const sliderData = [
    {
        id: 1,
        title : "COMPLETE AUTO SERVICE"
    },
    {
        id: 2,
        title: "QUALITY MAINTENANCE"
    },
    {
        id: 3,
        title : "FAIR FRIENDLY SERVICE"
    }

]

export default sliderData;